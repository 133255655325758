/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@splidejs/splide@3.6.12/dist/js/splide.min.js
 * - /npm/body-scroll-lock@4.0.0-beta.0/lib/bodyScrollLock.min.js
 * - /npm/filepond@4.32.7/dist/filepond.min.js
 * - /npm/alpinejs@3.14.8/dist/cdn.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
